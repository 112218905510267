import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  Text,
  Flex,
  Icon,
  PopoverBody,
  PopoverHeader,
  VStack,
  Center,
  Button,
  Box,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react';
import {
  useGetCategoriesQuery,
  useLazyGetFaqByIdQuery,
} from '@spartalabs/pdc-core';
import { Card } from 'components/Cards/Card';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { CardSkeleton } from 'components/Skeletons/Card';
import { HtmlStlyed } from 'pages/FaQ/components/HtmlParser';
import { AutoComplete } from 'pages/FaQ/components/Search/components/autocomplete';

interface HelpTabProps {
  categoryId: string | null;
  setCategoryId: (id: string | null) => void;
}

const HelpTab: React.FC<HelpTabProps> = ({ categoryId, setCategoryId }) => {
  const [questionId, setQuestionId] = useState<string | null>(null);

  const {
    data: categories,
    isLoading: catLoading,
    isError: catError,
    refetch,
  } = useGetCategoriesQuery();

  const [
    fetchQuestion,
    {
      isLoading: questionLoading,
      isFetching: questionFetching,
      isError: questionError,
      data,
    },
  ] = useLazyGetFaqByIdQuery();

  const questions = useMemo(() => {
    if (categories) {
      return categories?.filter(category => category.id === categoryId)[0]
        ?.FAQs;
    }
  }, [categories, categoryId]);

  const { title, desc } = useMemo(() => {
    if (questionId && data)
      return {
        title: data.title,
        desc: data.desc,
      };

    if (categoryId && categories) {
      const category = categories?.filter(
        category => category.id === categoryId,
      )[0];

      return {
        title: category.title,
        desc: category.desc,
      };
    }
    return {
      title: 'Como podemos te ajudar?',
      desc: 'Se já é nosso cliente ou não, pode encontrar informações importantes a baixo.',
    };
  }, [categories, data, questionId, categoryId]);

  const handleComeBack = useCallback(() => {
    if (questionId) return setQuestionId(null);

    if (categoryId) return setCategoryId(null);
  }, [questionId, categoryId]);

  const titleLoading = catLoading || questionFetching || questionLoading;

  useEffect(() => {
    if (questionId && data?.id !== questionId)
      fetchQuestion({ id: questionId });
  }, [questionId]);

  return (
    <>
      <PopoverHeader
        borderTopRadius="20px"
        bgGradient="linear(to-l, mutable.300, mutable.400,  mutable.500)"
        textColor="black"
        p="20px"
      >
        <Flex alignItems="center" justifyContent="center">
          {(questionId || categoryId) && (
            <Icon
              position="absolute"
              left="20px"
              onClick={() => handleComeBack()}
              w="20px"
              h="6"
              color="white"
              as={IoIosArrowBack}
            />
          )}
          <Text fontSize="24px" fontWeight={600}>
            Ajuda
          </Text>
        </Flex>
      </PopoverHeader>

      <PopoverBody
        py="20px"
        maxHeight="544px"
        overflow="scroll"
        bg="background"
        h="544px"
        sx={{
          '&::-webkit-scrollbar': {
            backgroundColor: 'grey.400',
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.500',
            borderRadius: '8px',
          },
        }}
      >
        <Card
          px="16px"
          py="24px"
          zIndex={2}
          bg="background"
          position="relative"
        >
          {titleLoading ? (
            <>
              <SkeletonText noOfLines={1} skeletonHeight="20px" />
              <SkeletonText noOfLines={3} />
            </>
          ) : (
            <>
              <Text fontSize="20px" textColor="blue.300" fontWeight={600}>
                {title}
              </Text>

              <Text
                mr="60px"
                mt="8px"
                mb="18px"
                textColor="text.100"
                lineHeight="18px"
                fontSize="16px"
                noOfLines={3}
              >
                {desc}
              </Text>
            </>
          )}

          {categories && !catLoading && !titleLoading && (
            <Skeleton isLoaded={!titleLoading}>
              <AutoComplete
                isWidget
                setCategoryId={setCategoryId}
                setQuestionId={setQuestionId}
                data={categories}
              />
            </Skeleton>
          )}
        </Card>
        <VStack gap="20px" mt="30px">
          {!categoryId && !questionId && catLoading ? (
            [0, 1, 2, 3].map(fakeNatty => (
              <CardSkeleton key={fakeNatty} h="44px" w="100%">
                <></>
              </CardSkeleton>
            ))
          ) : !categoryId && !questionId && catError ? (
            <ErrorCard
              bg="background"
              border="none"
              boxShadow="none"
              w="100%"
              refetch={refetch}
            />
          ) : (
            !categoryId &&
            !questionId &&
            categories?.map(category => (
              <Flex
                key={category.id}
                w="100%"
                p="3px"
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                borderRadius="4px"
                border="1px solid "
                borderColor="grey.200"
              >
                <Text ml="18px" textColor="text.100" fontSize="14px">
                  {category.title}
                </Text>

                <Center
                  onClick={() => setCategoryId(category.id)}
                  data-cy="close-filter"
                  as="button"
                  left={-5}
                  bg="grey.200"
                  w="40px"
                  h="40px"
                  borderRadius="4px"
                >
                  <Icon
                    w="24px"
                    h="8"
                    color="blue.300"
                    as={IoIosArrowForward}
                  />
                </Center>
              </Flex>
            ))
          )}

          {categoryId &&
            !questionId &&
            questions?.map(question => (
              <Card w="100%" key={question.id}>
                <Text
                  fontSize="18px"
                  fontWeight={600}
                  textColor="text.100"
                  lineHeight="22px"
                  mr="40px"
                  noOfLines={2}
                >
                  {question.title}
                </Text>

                <Text
                  noOfLines={2}
                  lineHeight="20px"
                  mr="40px"
                  mt="8px"
                  textColor="text.200"
                >
                  {question.desc}
                </Text>

                <Button onClick={() => setQuestionId(question.id)} mt="24px">
                  Conheça mais
                </Button>
              </Card>
            ))}

          {questionId && (questionLoading || questionFetching) ? (
            <Card w="100%">
              <Box>
                <CardSkeleton w="200px" h="20px">
                  <></>
                </CardSkeleton>
              </Box>
              <SkeletonText
                w="100%"
                mx="auto"
                mt="5"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
                mb="16px"
              />
            </Card>
          ) : questionError ? (
            <ErrorCard
              bg="background"
              border="none"
              boxShadow="none"
              w="100%"
              refetch={refetch}
            />
          ) : (
            questionId && (
              <Card>
                <HtmlStlyed
                  title={data?.title}
                  value={data?.htmlContent}
                  isWidget
                />
              </Card>
            )
          )}
        </VStack>
      </PopoverBody>
    </>
  );
};

export default HelpTab;
