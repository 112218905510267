import React, { ReactNode, useMemo, useRef } from 'react';
import {
  Flex,
  HStack,
  FlexProps,
  Box,
  Skeleton,
  VStack,
  Wrap,
  useDimensions,
} from '@chakra-ui/react';
import { SIZE_CHANGE } from 'utils/constants';
import { CardSkeleton } from './Card';

interface ChartCard extends FlexProps {
  title?: string;
  customTitle?: ReactNode;
  subtitleMb?: string | number;
  subtitle?: string;
  hasFilter?: boolean;
  filters?: ChartTypeFilter[];
  selectedFilter?: ChartTypeFilter;
  setFilter?: React.Dispatch<React.SetStateAction<ChartTypeFilter>>;
  picker?: string[];
  pickerType?: string;
  setPicked?: React.Dispatch<React.SetStateAction<string>>;
  firstPicked?: string;
  setDateRange?: React.Dispatch<React.SetStateAction<DateProps[]>>;
  startDate?: DateProps[];
  details?: () => void;
  children: React.ReactNode;
  from?: string;
  hasDate?: boolean;
  cardName?: string;
  isError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch?: any;
  cardOptions?: CardOptions;
  cardRef?: CardRef;
}

export const ChartCardSkele: React.FC<ChartCard> = ({
  details,
  children,
  picker,
  filters,
  setFilter,
  setPicked,
  from,
  setDateRange,
  cardOptions,
  ...rest
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useDimensions(boxRef, true);

  const innerBoxDim = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const size: any = rest.w;

    if (size?.lg === '50%')
      return {
        ...dimensions,
        contentBox: { ...dimensions?.contentBox, width: SIZE_CHANGE - 1 },
      };

    return dimensions;
  }, [dimensions, rest.w]);

  return (
    <CardSkeleton {...rest}>
      <Box
        ref={boxRef}
        display="flex"
        flexDir="column"
        flex={1}
        justifyContent="space-between"
        my="24px"
        mx="20px"
      >
        <VStack align="flex-start" mb="8px">
          <Flex
            w="100%"
            justifyContent="space-between"
            justifySelf="flex-start"
          >
            <Skeleton w="134px" h="20px" />
            <Flex>
              <HStack
                ml="16px"
                spacing="16px"
                display={
                  innerBoxDim?.contentBox?.width &&
                  innerBoxDim?.contentBox.width < SIZE_CHANGE
                    ? 'none'
                    : from !== 'home'
                    ? { base: 'none', '2xl': 'flex' }
                    : { base: 'none', xl: 'flex' }
                }
              >
                {filters && setFilter && (
                  <HStack spacing="18px">
                    {filters.map((filter, index) => (
                      <Skeleton key={index} w="30px" h="30px" />
                    ))}
                  </HStack>
                )}

                {setDateRange && <Skeleton w="70px" h="28px" />}

                {!!picker && setPicked && <Skeleton w="50px" h="28px" />}
              </HStack>
              <Wrap ml="16px" spacing="16px" justify="flex-end" align="center">
                {!!details && <Skeleton w="85px" h="20px" />}
                {!!cardOptions && <Skeleton w="40px" h="28px" />}
              </Wrap>
            </Flex>
          </Flex>
          <Skeleton mb="8px" w="190px" h="12px" />
        </VStack>

        <VStack
          display={
            innerBoxDim?.contentBox?.width &&
            innerBoxDim?.contentBox.width < SIZE_CHANGE
              ? 'flex'
              : from !== 'home'
              ? { base: 'flex', '2xl': 'none' }
              : { base: 'flex', xl: 'none' }
          }
          // align="start"
          // spacing="18px"
          align="space-between"
          mb="20px"
        >
          {filters && setFilter && (
            <HStack h="100%" spacing="14px" mb="8px">
              {filters.map((filter, index) => (
                <Skeleton key={index} w="50px" h="40px" />
              ))}
            </HStack>
          )}
          <Wrap h="100%" spacing="14px" align="center">
            {setDateRange && <Skeleton w="70px" h="28px" />}
            {!!picker && setPicked && <Skeleton w="50px" h="28px" />}
          </Wrap>
        </VStack>
        <Flex flex={1}>{children}</Flex>
      </Box>
    </CardSkeleton>
  );
};
