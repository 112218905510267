import React, { useCallback } from 'react';
import {
  Progress,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

export const TableSkeleton: React.FC<{ labels?: TableLabel[] }> = ({
  labels,
}) => {
  const renderSkeletonRows = useCallback(() => {
    return labels?.map(label =>
      label.isShown === false ? null : (
        <Td
          key={label.title}
          borderColor="grey.200"
          fontSize="12px"
          color="grey.600"
        >
          <Skeleton w="100px" h="20px" />
        </Td>
      ),
    );
  }, [labels]);

  return (
    <>
      <Progress
        w="100%"
        h="100%"
        position="absolute"
        bg="transparent"
        zIndex={1}
        colorScheme="whiteAlpha"
        isIndeterminate
        overflow="hidden"
      />
      <Table opacity="50%" w="100%">
        <Thead
          borderLeft="1px solid"
          borderRight="1px solid"
          borderColor="grey.200"
          bg="grey.400"
        >
          <Tr>
            {labels?.map((label: TableLabel) =>
              label.isShown === false ? null : (
                <Th
                  key={label.title}
                  fontWeight={400}
                  fontSize="12px"
                  color="black"
                  borderColor="grey.200"
                >
                  {label.title}
                </Th>
              ),
            )}
          </Tr>
        </Thead>
        <Tbody>
          <Tr
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="grey.200"
          >
            {renderSkeletonRows()}
          </Tr>
          <Tr
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="grey.200"
          >
            {renderSkeletonRows()}
          </Tr>
          <Tr
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="grey.200"
          >
            {renderSkeletonRows()}
          </Tr>
          <Tr
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="grey.200"
          >
            {renderSkeletonRows()}
          </Tr>
          <Tr
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="grey.200"
          >
            {renderSkeletonRows()}
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};
