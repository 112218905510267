import React from 'react';
import { MdClose } from 'react-icons/md';
import { Flex, Icon, Text } from '@chakra-ui/react';

interface PlateDisplayProps {
  removeSearchPlates: (plate: string) => void;
  searchPlates?: string[];
}

const PlateDisplay = ({
  removeSearchPlates,
  searchPlates,
}: PlateDisplayProps) => {
  return (
    <Flex flexWrap="wrap" mt="12px" gap="8px">
      {searchPlates?.map(item => (
        <Flex
          w="88px"
          px="4px"
          key={item}
          borderRadius="6px"
          alignSelf="flex-start"
          alignItems="center"
          border="1px solid"
          borderColor="gray.300"
          backgroundColor="#F2F4F7"
        >
          <Text lineHeight="18px" fontSize="12px" textColor="grey.800">
            {item}
          </Text>
          <Icon
            cursor="pointer"
            onClick={() => removeSearchPlates(item)}
            color="#667085"
            h="16px"
            w="16px"
            aria-label="Excluir grupo"
            as={MdClose}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default PlateDisplay;
