import React from 'react';
import { HiOutlineFilter } from 'react-icons/hi';
import { MdOutlineDownloading } from 'react-icons/md';
import { Button, HStack, Icon, Select, Spinner, Box } from '@chakra-ui/react';
import { DateRangeComp, Onboarding } from 'components';
import SimpleInput from 'components/SimpleInput';
import { logsTexts } from 'utils/texts';
import PlateDisplay from './PlateDisplay';

const DOWNLOAD_LABEL = 'Baixar planilha';

interface WebHeaderProps extends BaseHeaderProps {
  step?: number | null;
}

const WebHeader = ({
  step,
  hasGlobalSearch,
  inputValue,
  costCenters,
  fetchDownload,
  setDateRange,
  initDate,
  isError,
  handleFilter,
  searchHolder,
  onDateRangeChange,
  handleCostCenter,
  internalOnDownload,
  downloadLoading,
  costCenter,
  setSearchTerm,
  filters,
  searchPlates,
  removeSearchPlates,
  hasPlateSearch,
  customComponent,
  filterPerDay,
}: WebHeaderProps) => {
  return (
    <Box display={{ base: 'none', md: 'block' }} mb="20px" ml="32px">
      <HStack spacing="18px" justify="end">
        {hasGlobalSearch && (
          <Onboarding
            isOpen={step === 2}
            step={2}
            contents={logsTexts.step2}
            placement="left-start"
            type="logs"
          >
            <SimpleInput
              height="40px"
              isDisabled={isError}
              aria-label="Buscar informações"
              id="search"
              minW="180px"
              type="search-ghost"
              w={handleFilter ? '300px ' : '150px'}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder={searchHolder || 'Buscar...'}
              value={inputValue}
            />
          </Onboarding>
        )}

        {customComponent && customComponent()}

        {setDateRange && (
          <DateRangeComp
            dateName="table-1"
            type={filterPerDay ? 'Dia' : 'month'}
            startDate={initDate}
            ref={onDateRangeChange}
            minW="186"
          />
        )}

        {filters &&
          Object.keys(filters).map(key => {
            const { labels, state, handler, aria, defaultValue } = filters[key];
            if (labels.length < 1) return;
            return (
              <Select
                borderColor="grey.500"
                color="text.100"
                fontSize="14px"
                fontWeight="500"
                key={key}
                onChange={event =>
                  handler(
                    labels?.filter(
                      option => option?.name === event.target.value,
                    )?.[0] || { name: defaultValue, value: null },
                  )
                }
                value={state.name || defaultValue}
                aria-label={aria}
              >
                {[{ name: defaultValue, value: null }, ...labels].map(
                  option => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ),
                )}
              </Select>
            );
          })}

        {costCenters && handleCostCenter && (
          <Select
            borderColor="grey.500"
            color="text.100"
            fontSize="14px"
            fontWeight="500"
            onChange={event =>
              handleCostCenter(
                costCenters?.filter(
                  option => option?.name === event.target.value,
                )?.[0] || null,
              )
            }
            value={costCenter?.name || 'Selecione um centro de custo'}
            maxW="200px"
            aria-label="Centros de custo"
          >
            {costCenters.map(option => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </Select>
        )}

        {fetchDownload && (
          <HStack>
            <Button
              onClick={internalOnDownload}
              variant="link"
              rightIcon={downloadLoading ? undefined : <MdOutlineDownloading />}
            >
              {DOWNLOAD_LABEL}
            </Button>
            {downloadLoading && <Spinner size="xs" color="blue.0" />}
          </HStack>
        )}

        {handleFilter && (
          <Onboarding
            isOpen={step === 3}
            step={3}
            contents={logsTexts.step3}
            placement="left-start"
            type="logs"
            bg="white"
            borderRadius="8px"
          >
            <Button
              minW="92px"
              data-cy="filter"
              variant="outline"
              leftIcon={
                <Icon
                  color="mutable.100"
                  as={HiOutlineFilter}
                  h="20px"
                  w="20px"
                />
              }
              onClick={handleFilter}
              fontWeight={400}
            >
              Filtrar
            </Button>
          </Onboarding>
        )}
      </HStack>
      {hasPlateSearch && (
        <PlateDisplay
          searchPlates={searchPlates}
          removeSearchPlates={removeSearchPlates}
        />
      )}
    </Box>
  );
};

export default WebHeader;
