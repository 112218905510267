import React from 'react';
import { Flex, HStack, Skeleton, SkeletonCircle, Box } from '@chakra-ui/react';
import { CardSkeleton } from './Card';

interface MultiInfoSkeletonProps {
  w?: string;
}

export const MultiInfoSkeleton: React.FC<MultiInfoSkeletonProps> = ({ w }) => {
  return (
    <CardSkeleton
      h={{ base: '100%', lg: w === '50%' ? '374px' : '100%' }}
      w={{ base: '100%', lg: w }}
    >
      <Flex
        m="16px"
        flex={1}
        align="flex-start"
        justify="center"
        flexDir="column"
      >
        <Flex w="100%" justifyContent="space-between" justifySelf="flex-start">
          <Skeleton w="134px" h="20px" />

          <HStack ml="8px" spacing="22px">
            <Skeleton w="85px" h="20px" />
            <Skeleton w="40px" h="28px" />
          </HStack>
        </Flex>
        <Skeleton mb="20px" w="168px" h="12px" />

        <Flex
          justifyContent={{ base: 'start', '2xl': 'space-between' }}
          gap={4}
          alignItems="flex-start"
          flexWrap="wrap"
          w="100%"
        >
          {[0, 1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Box key={index}>
                <Flex align="center">
                  <SkeletonCircle />
                  <Skeleton ml="4px" w="40px" h="12px" />
                </Flex>
                <Skeleton mt="8px" mb="8px" w="148px" h="12px" />
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </CardSkeleton>
  );
};
