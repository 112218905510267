import React, { ReactNode, useState, useEffect, useRef, useMemo } from 'react';
import { VscLinkExternal } from 'react-icons/vsc';
import {
  Flex,
  Text,
  Button,
  HStack,
  FlexProps,
  VStack,
  IconButton,
  Tooltip,
  useDimensions,
  Box,
} from '@chakra-ui/react';
import {
  CardOptionsMenu,
  DateRangeComp,
  ErrorCard,
  SegmentedPicker,
} from 'components';
import { ChartCardSkele } from 'components/Skeletons/ChartCard';
import { ChartSkeleton } from 'components/Skeletons/Charts';
import { useDateSelect } from 'hooks/useDateSelect';
import { useSegmentedPicker } from 'hooks/useSegmentedPicker';
import { DETAILS_LABEL, SIZE_CHANGE } from 'utils/constants';
import { Card } from './Card';

interface ChartCard extends FlexProps {
  title?: string;
  customTitle?: ReactNode;
  subtitleMb?: string | number;
  subtitle?: string;
  hasFilter?: boolean;
  filters?: ChartTypeFilter[];
  selectedFilter?: ChartTypeFilter;
  setFilter?: React.Dispatch<React.SetStateAction<ChartTypeFilter>>;
  picker?: string[];
  pickerType?: string;
  setPicked?: React.Dispatch<React.SetStateAction<string>>;
  firstPicked?: string;
  setDateRange?: React.Dispatch<React.SetStateAction<DateProps[]>>;
  startDate?: DateProps[];
  details?: () => void;
  children: React.ReactNode;
  from?: string;
  hasDate?: boolean;
  cardName?: string;
  isError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch?: any;
  cardOptions?: CardOptions;
  cardRef?: CardRef;
  isLoading?: boolean;
  skeletonType?: string;
}

export const ChartCard: React.FC<ChartCard> = ({
  title,
  customTitle,
  subtitleMb,
  details,
  subtitle,
  children,
  picker,
  pickerType,
  filters,
  selectedFilter,
  setFilter,
  setPicked,
  firstPicked,
  from,
  setDateRange,
  startDate,
  cardName,
  isError,
  refetch,
  cardOptions,
  cardRef,
  isLoading,
  skeletonType,
  ...rest
}) => {
  const { onDateRangeChange } = useDateSelect(setDateRange);
  const { onSegmentedPickerChange } = useSegmentedPicker(setPicked);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useDimensions(boxRef, true);

  const innerBoxDim = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const size: any = rest.w;

    if (size.lg === '50%')
      return {
        ...dimensions,
        contentBox: { ...dimensions?.contentBox, width: SIZE_CHANGE - 1 },
      };

    return dimensions;
  }, [dimensions, rest]);

  const [currentSelectedFilter, setCurrentSelectedFilter] =
    useState<ChartTypeFilter>(
      selectedFilter || filters?.[0] || { label: '', icon: null, type: '' },
    );

  useEffect(() => {
    if (setFilter) setFilter(currentSelectedFilter);
  }, [currentSelectedFilter]);

  if (isLoading)
    return (
      <Card
        p="0px"
        cardOptions={cardOptions}
        cardRef={cardRef}
        dragHandler={cardOptions?.dragHandler}
        {...rest}
      >
        <ChartCardSkele
          filters={filters}
          setFilter={setFilter}
          details={details}
          setDateRange={setDateRange}
          picker={picker}
          setPicked={setPicked}
          cardOptions={cardOptions}
        >
          <ChartSkeleton type={skeletonType} />
        </ChartCardSkele>
      </Card>
    );

  if (isError)
    return <ErrorCard {...rest} refetch={() => (refetch ? refetch() : null)} />;

  return (
    <Card
      cardOptions={cardOptions}
      cardRef={cardRef}
      dragHandler={cardOptions?.dragHandler}
      {...rest}
    >
      <Box
        ref={boxRef}
        display="flex"
        flexDir="column"
        flex={1}
        justifyContent="space-between"
      >
        <VStack
          align="flex-start"
          mb={{ base: '18px', xl: subtitleMb || '24px' }}
        >
          <Flex
            w="100%"
            justifyContent="space-between"
            justifySelf="flex-start"
          >
            {customTitle ? (
              customTitle
            ) : (
              <Text
                alignSelf="center"
                color="text.100"
                fontSize="16px"
                fontWeight={400}
              >
                {title}
              </Text>
            )}

            <Flex>
              <HStack
                ml="16px"
                spacing="16px"
                display={
                  innerBoxDim?.contentBox?.width &&
                  innerBoxDim?.contentBox.width < SIZE_CHANGE
                    ? 'none'
                    : from !== 'home'
                    ? { base: 'none', '2xl': 'flex' }
                    : { base: 'none', xl: 'flex' }
                }
              >
                {filters && setFilter && (
                  <HStack spacing="18px">
                    {filters.map((filter, index) => (
                      <Tooltip
                        key={index}
                        label={filter.label}
                        bg="ocean.600"
                        color="white"
                        borderRadius="4px"
                      >
                        <IconButton
                          color="ocean.600"
                          bg="transparent"
                          p={1}
                          _active={{ color: 'white', bg: 'ocean.600' }}
                          _hover={{ color: 'white', bg: 'ocean.600' }}
                          isActive={selectedFilter === filter}
                          aria-label={`Filtrar por ${filter.label}`}
                          size=""
                          icon={React.createElement(filter.icon, {
                            size: '14px',
                          })}
                          onClick={() => setCurrentSelectedFilter(filter)}
                        />
                      </Tooltip>
                    ))}
                  </HStack>
                )}

                {setDateRange && (
                  <DateRangeComp
                    type={pickerType === 'date' ? firstPicked : 'month'}
                    startDate={startDate}
                    ref={onDateRangeChange}
                    minW="170px"
                  />
                )}

                {!!picker && setPicked && (
                  <SegmentedPicker
                    firstSelectedLabel={firstPicked}
                    ref={onSegmentedPickerChange}
                    labels={picker}
                  />
                )}
              </HStack>
              <HStack
                flexWrap="wrap"
                ml="16px"
                spacing="16px"
                justify="flex-end"
              >
                {!!details && (
                  <Button
                    display={{ sm: 'flex', base: 'none' }}
                    data-cy={`details-${cardName}`}
                    onClick={details}
                    variant="link"
                    rightIcon={<VscLinkExternal />}
                    ml="22px"
                  >
                    {DETAILS_LABEL}
                  </Button>
                )}
                {!!cardOptions && <CardOptionsMenu values={cardOptions} />}
              </HStack>
            </Flex>
          </Flex>
          <Text
            textAlign="start"
            color="text.300"
            fontSize="12px"
            fontWeight={500}
          >
            {subtitle || ''}
          </Text>
        </VStack>

        <VStack
          display={
            innerBoxDim?.contentBox?.width &&
            innerBoxDim?.contentBox.width < SIZE_CHANGE
              ? 'flex'
              : from !== 'home'
              ? { base: 'flex', '2xl': 'none' }
              : { base: 'flex', xl: 'none' }
          }
          // align="start"
          // spacing="18px"
          align="space-between"
          mb="20px"
        >
          {filters && setFilter && (
            <HStack h="100%" spacing="14px" mb="8px">
              {filters.map((filter, index) => (
                <Button
                  aria-label={`Filtrar por ${filter.label}`}
                  whiteSpace="unset"
                  key={index}
                  variant="ghost"
                  flexDir="column"
                  leftIcon={React.createElement(filter.icon, {
                    size: '18px',
                    style: { marginBottom: '8px', marginLeft: '8px' },
                  })}
                  iconSpacing="8px"
                  h="52px"
                  textColor={
                    currentSelectedFilter === filter ? 'text.100' : 'text.200'
                  }
                  _hover={{ textColor: 'text.100' }}
                  _focus={{ bg: 'none' }}
                  fontSize="10px"
                  onClick={() => setCurrentSelectedFilter(filter)}
                >
                  {filter.label}
                </Button>
              ))}
            </HStack>
          )}
          {!!details && (
            <Button
              display={{ sm: 'none', base: 'flex' }}
              data-cy={`details-${cardName}`}
              onClick={details}
              variant="link"
              rightIcon={<VscLinkExternal />}
              alignSelf="flex-start"
            >
              {DETAILS_LABEL}
            </Button>
          )}
          <HStack flexWrap="wrap" h="100%" spacing="14px" align="center">
            {setDateRange && (
              <DateRangeComp
                dateName={cardName}
                type={pickerType === 'date' ? firstPicked : 'month'}
                startDate={startDate}
                ref={onDateRangeChange}
                minW="186"
              />
            )}
            {!!picker && setPicked && (
              <SegmentedPicker
                pickerName={cardName}
                firstSelectedLabel={firstPicked}
                ref={onSegmentedPickerChange}
                labels={picker}
              />
            )}
          </HStack>
        </VStack>

        <Flex h="200px">{children}</Flex>
      </Box>
    </Card>
  );
};
