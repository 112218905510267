import React, { ReactNode } from 'react';
import { Card, FlexProps, Progress } from '@chakra-ui/react';

interface CardSkeleProps extends FlexProps {
  children: ReactNode;
}

export const CardSkeleton: React.FC<CardSkeleProps> = ({
  children,
  w,
  h,
  ...props
}) => {
  return (
    <Card w={w} h={h} bg="grey.200" {...props}>
      <Progress
        w="100%"
        h="100%"
        position="absolute"
        bg="transparent"
        zIndex={1}
        colorScheme="whiteAlpha"
        isIndeterminate
      />
      {children}
    </Card>
  );
};
