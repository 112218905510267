import React from 'react';
import { HiOutlineFilter } from 'react-icons/hi';
import { MdOutlineDownloading } from 'react-icons/md';
import {
  Button,
  HStack,
  Icon,
  Select,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { DateRangeComp } from 'components';
import SimpleInput from 'components/SimpleInput';
import PlateDisplay from './PlateDisplay';

const DOWNLOAD_LABEL = 'Baixar planilha';

interface MobileHeaderProps extends BaseHeaderProps {
  subtitleMb?: string | number;
}

const MobileHeader = ({
  subtitleMb,
  hasGlobalSearch,
  costCenters,
  fetchDownload,
  setDateRange,
  initDate,
  isError,
  handleFilter,
  searchHolder,
  onDateRangeChange,
  handleCostCenter,
  internalOnDownload,
  downloadLoading,
  costCenter,
  setSearchTerm,
  removeSearchPlates,
  filters,
  hasPlateSearch,
  searchPlates,
  customComponent,
  filterPerDay,
}: MobileHeaderProps) => {
  return (
    <VStack
      mb={{ base: '18px', md: subtitleMb || '22px' }}
      align="start"
      w="100%"
      display={{ base: 'flex', md: 'none' }}
    >
      {fetchDownload && (
        <HStack>
          <Button
            onClick={internalOnDownload}
            variant="link"
            rightIcon={downloadLoading ? undefined : <MdOutlineDownloading />}
          >
            {DOWNLOAD_LABEL}
          </Button>
          {downloadLoading && <Spinner size="xs" color="blue.0" />}
        </HStack>
      )}

      <HStack
        alignItems={{ base: 'flex-start', sm: 'center' }}
        flexDir={{ base: 'column', sm: 'row' }}
        gap="8px"
      >
        {setDateRange && (
          <DateRangeComp
            dateName="table"
            type={filterPerDay ? 'Dia' : 'month'}
            startDate={initDate}
            ref={onDateRangeChange}
            minW="186px"
          />
        )}

        {filters &&
          Object.keys(filters).map(key => {
            const { labels, state, handler, aria, defaultValue } = filters[key];
            if (labels.length < 1) return;
            return (
              <Select
                borderColor="grey.500"
                color="text.100"
                fontSize="14px"
                fontWeight="500"
                key={key}
                onChange={event =>
                  handler(
                    labels?.filter(
                      option => option.name === event.target.value,
                    )?.[0] || null,
                  )
                }
                value={state.name || defaultValue}
                aria-label={aria}
              >
                {[{ name: defaultValue, value: null }, ...labels].map(
                  option => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ),
                )}
              </Select>
            );
          })}

        {costCenters && handleCostCenter && (
          <Select
            borderColor="grey.500"
            color="text.100"
            fontSize="14px"
            fontWeight="500"
            onChange={event =>
              handleCostCenter(
                costCenters?.filter(
                  option => option.name === event.target.value,
                )?.[0] || null,
              )
            }
            value={costCenter?.name || 'Selecione um centro de custo'}
            aria-label="Centros de Custo"
          >
            {costCenters.map(option => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </Select>
        )}

        {customComponent && customComponent()}

        {hasGlobalSearch && (
          <SimpleInput
            isDisabled={isError}
            aria-label="Buscar informações"
            data-cy="search-1"
            type="search-ghost"
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={searchHolder || 'Buscar...'}
          />
        )}
        {handleFilter && (
          <Button
            minW="92px"
            data-cy="filter"
            variant="outline"
            leftIcon={
              <Icon
                color="mutable.100"
                as={HiOutlineFilter}
                h="20px"
                w="20px"
              />
            }
            onClick={handleFilter}
            fontWeight={400}
          >
            Filtrar
          </Button>
        )}
      </HStack>
      {hasPlateSearch && (
        <PlateDisplay
          searchPlates={searchPlates}
          removeSearchPlates={removeSearchPlates}
        />
      )}
    </VStack>
  );
};

export default MobileHeader;
