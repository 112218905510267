import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { CardSkeleton } from './Card';

export const VehicleSkeleton: React.FC = () => {
  return (
    <>
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <CardSkeleton h="20px" mt="52px" w="120px">
          <Skeleton w="120px" h="20px" />
        </CardSkeleton>

        <CardSkeleton mt="52px" w="120px">
          <Skeleton w="120px" h="32px" />
        </CardSkeleton>
      </Flex>

      <CardSkeleton w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkeleton>
      <CardSkeleton w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkeleton>
      <CardSkeleton w="100%" mt="24px" h="92px">
        <Skeleton />
      </CardSkeleton>
    </>
  );
};
